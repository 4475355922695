interface StateMachineElement extends HTMLElement {
	updateState ( state: string ): void
}

function isStateMachineElement( x: HTMLElement ): x is StateMachineElement {
	return ( x as StateMachineElement ).updateState !== undefined && ( 'function' === typeof( x as StateMachineElement ).updateState );
}

export class MrInputSink extends HTMLElement {
	static get observedAttributes(): Array<string> {
		return [
			'data-directive',
			'disabled',
			'data-for',
			'data-keys',
			'data-directive-focusin',
			'data-directive-focusout',
		];
	}

	// Event Handlers
	#clickHandler = ( e: MouseEvent ): void => {
		if ( this.disabled ) {
			return;
		}

		if ( e && e.metaKey ) { // ignore clicks with modifier keys : shift, ctrl, alt,...
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		this.trigger( this.directive );
	};

	#focusinHandler = (): void => {
		if ( this.disabled ) {
			return;
		}

		this.trigger( this.directiveFocusin );
	};

	#focusoutHandler = (): void => {
		if ( this.disabled ) {
			return;
		}

		this.trigger( this.directiveFocusout );
	};

	#keydownHandler = ( e: KeyboardEvent ): void => {
		if ( this.disabled ) {
			return;
		}

		if ( !e.key ) {
			return;
		}

		if ( !this.keys.includes( e.key.toString() ) ) {
			return;
		}

		const forEl = this.forEl;
		if ( !forEl ) {
			return;
		}

		if ( ( document.activeElement !== this ) && ( !this.contains( document.activeElement ) ) ) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		this.trigger( this.directive );
	};

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();
	}

	// Life cycle
	connectedCallback(): void {
		this._addEventListeners();
	}

	disconnectedCallback(): void {
		this._removeEventListeners();
	}

	// Attributes
	override setAttribute( attr: string, value: string ): void {
		if ( this.disabled ) {
			return;
		}

		super.setAttribute( attr, value );
	}

	override removeAttribute( attr: string ): void {
		if ( this.disabled && 'disabled' !== attr ) {
			return;
		}

		super.removeAttribute( attr );
	}

	get directive(): string|null {
		return this.getAttribute( 'data-directive' );
	}

	set directive( value: string|null ) {
		if ( value ) {
			this.setAttribute( 'data-directive', value );
		} else {
			this.removeAttribute( 'data-directive' );
		}
	}

	get directiveFocusin(): string|null {
		return this.getAttribute( 'data-directive-focusin' );
	}

	set directiveFocusin( value: string|null ) {
		if ( value ) {
			this.setAttribute( 'data-directive-focusin', value );
		} else {
			this.removeAttribute( 'data-directive-focusin' );
		}
	}

	get directiveFocusout(): string|null {
		return this.getAttribute( 'data-directive-focusout' );
	}

	set directiveFocusout( value: string|null ) {
		if ( value ) {
			this.setAttribute( 'data-directive-focusout', value );
		} else {
			this.removeAttribute( 'data-directive-focusout' );
		}
	}

	get disabled(): boolean {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( value: boolean ) {
		if ( value ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	get forEl(): StateMachineElement|null {
		const forID = this.getAttribute( 'data-for' );
		if ( !forID ) {
			return null;
		}

		const el = document.getElementById( forID );
		if ( !el ) {
			return null;
		}
		if ( !isStateMachineElement( el ) ) {
			return null;
		}

		return el;
	}

	set forEl( el: StateMachineElement|null ) {
		if ( !el ) {
			this.removeAttribute( 'data-for' );

			return;
		}

		if ( el.id ) {
			this.setAttribute( 'data-for', el.id );

			return;
		}
	}

	get keys(): Array<string> {
		const value = this.getAttribute( 'data-keys' );
		if ( !value ) {
			return [];
		}

		return value.split( ' ' );
	}

	set keys( value: Array<string> ) {
		if ( value ) {
			this.setAttribute( 'data-keys', value.join( ' ' ) );
		} else {
			this.removeAttribute( 'data-keys' );
		}
	}

	attributeChangedCallback( attrName: string, oldVal: string, newVal: string ): void {
		if ( 'disabled' === attrName ) {
			this._removeEventListeners();
			if ( null === newVal ) {
				this._addEventListeners();
			}

			return;
		}
	}

	_addEventListeners(): void {
		this.addEventListener( 'click', this.#clickHandler );
		this.addEventListener( 'focusin', this.#focusinHandler );
		this.addEventListener( 'focusout', this.#focusoutHandler );

		document.addEventListener( 'keydown', this.#keydownHandler );
	}

	_removeEventListeners(): void {
		this.removeEventListener( 'click', this.#clickHandler );
		this.removeEventListener( 'focusin', this.#focusinHandler );
		this.removeEventListener( 'focusout', this.#focusoutHandler );

		document.removeEventListener( 'keydown', this.#keydownHandler );
	}

	trigger( directive: string | null ): void {
		if ( this.disabled ) {
			return;
		}

		if ( !directive ) {
			return;
		}

		const forEl = this.forEl;
		if ( !forEl ) {
			return;
		}

		// forEl must be a state machine that implements "updateState(directive)"
		if ( !forEl.updateState ) {
			return;
		}

		forEl.updateState( directive );
	}
}
