/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*
*   File:   PopupMenu.js
*
*   Desc:   Popup menu widget that implements ARIA Authoring Practices
*/

/*
*   @constructor PopupMenu
*
*   @desc
*       Wrapper object for a simple popup menu (without nested submenus)
*
*   @param domNode
*       The DOM element node that serves as the popup menu container. Each
*       child element of domNode that represents a menuitem must have a
*       'role' attribute with value 'menuitem'.
*
*   @param controllerObj
*       The object that is a wrapper for the DOM element that controls the
*       menu, e.g. a button element, with an 'aria-controls' attribute that
*       references this menu's domNode. See MenuButton.js
*
*       The controller object is expected to have the following properties:
*       1. domNode: The controller object's DOM element node, needed for
*          retrieving positioning information.
*       2. hasHover: boolean that indicates whether the controller object's
*          domNode has responded to a mouseover event with no subsequent
*          mouseout event having occurred.
*/

import { MenuItemLink } from './mr-menu-item-link';
import { PopupMenuItem } from './mr-popup-menu-item';

import './types';

export const PopupMenu = function( this: PopupMenuType, domNode: HTMLElement, controllerObj: MenubuttonType ): void {
	const msgPrefix = 'PopupMenu constructor argument domNode ';

	// Check whether domNode has child elements
	if ( 0 === domNode.childElementCount ) {
		throw new Error( msgPrefix + 'has no element children.' );
	}

	this.domNode = domNode;
	this.controller = controllerObj;

	this.menuitems = []; // see PopupMenu init method
	this.firstChars = []; // see PopupMenu init method

	this.firstItem = null; // see PopupMenu init method
	this.lastItem = null; // see PopupMenu init method

	this.hasFocus = false; // see MenuItem handleFocus, handleBlur
	this.hasHover = false; // see PopupMenu handleMouseover, handleMouseout
} as any as { new ( domNode: HTMLElement, controllerObj: MenubuttonType ): PopupMenuType; }; // eslint-disable-line @typescript-eslint/no-explicit-any

/*
*   @method PopupMenu.prototype.init
*
*   @desc
*       Add domNode event listeners for mouseover and mouseout. Traverse
*       domNode children to configure each menuitem and populate menuitems
*       array. Initialize firstItem and lastItem properties.
*/
PopupMenu.prototype.init = function( this: PopupMenuType ) {
	let menuElement;
	let menuItem;
	let textContent;
	let label;

	// Configure the domNode itself
	this.domNode.tabIndex = -1;

	this.domNode.setAttribute( 'role', 'menu' );

	if ( !this.domNode.getAttribute( 'aria-labelledby' ) && !this.domNode.getAttribute( 'aria-label' ) && !this.domNode.getAttribute( 'title' ) ) {
		label = this.controller.domNode.innerHTML;
		this.domNode.setAttribute( 'aria-label', label );
	}

	this.domNode.addEventListener( 'mouseover', this.handleMouseover.bind( this ) );
	this.domNode.addEventListener( 'mouseout', this.handleMouseout.bind( this ) );

	// Traverse the element children of domNode: configure each with
	// menuitem role behavior and store reference in menuitems array.
	const menuElements = this.domNode.getElementsByTagName( 'LI' );

	for ( let i = 0; i < menuElements.length; i++ ) {
		menuElement = menuElements[i];

		if ( !( menuElement instanceof HTMLElement ) ) {
			continue;
		}

		if (
			'separator' !== menuElement.getAttribute( 'role' ) &&
			'none' !== menuElement.getAttribute( 'role' )
		) {
			menuItem = new PopupMenuItem( menuElement, this );
			menuItem.init();
			this.menuitems.push( menuItem );
			textContent = ( menuElement.textContent ?? '' ).trim();
			this.firstChars.push( textContent.substring( 0, 1 ).toLowerCase() );

		} else if (
			'none' === menuElement.getAttribute( 'role' )
		) {
			const a = menuElement.querySelector( 'a' );
			if ( a ) {
				menuItem = new MenuItemLink( a, this );
				menuItem.init();
				this.menuitems.push( menuItem );
				textContent = ( menuElement.textContent ?? '' ).trim();
				this.firstChars.push( textContent.substring( 0, 1 ).toLowerCase() );
			}
		}
	}

	// Use populated menuitems array to initialize firstItem and lastItem.
	const numItems = this.menuitems.length || 0;
	if ( 0 < numItems ) {
		this.firstItem = this.menuitems[0];
		this.lastItem = this.menuitems[numItems - 1];
	}
};

/* EVENT HANDLERS */

PopupMenu.prototype.handleMouseover = function( ) {
	this.hasHover = true;
};

PopupMenu.prototype.handleMouseout = function( ) {
	this.hasHover = false;
};

/* FOCUS MANAGEMENT METHODS */

PopupMenu.prototype.setFocusToController = function( command: string ) {
	if ( 'previous' === command ) {
		this.controller.menubutton.setFocusToPreviousItem( this.controller );
	} else if ( 'next' === command ) {
		this.controller.menubutton.setFocusToNextItem( this.controller );
	} else {
		this.controller.domNode.focus();
	}
};

PopupMenu.prototype.setFocusToFirstItem = function() {
	this.firstItem.domNode.focus();
};

PopupMenu.prototype.setFocusToLastItem = function() {
	this.lastItem.domNode.focus();
};

PopupMenu.prototype.setFocusToPreviousItem = function( currentItem: PopupMenuItemType ) {
	let index;

	if ( currentItem === this.firstItem ) {
		this.lastItem.domNode.focus();
	} else {
		index = this.menuitems.indexOf( currentItem );
		this.menuitems[index - 1].domNode.focus();
	}
};

PopupMenu.prototype.setFocusToNextItem = function( currentItem: PopupMenuItemType ) {
	let index;

	if ( currentItem === this.lastItem ) {
		this.firstItem.domNode.focus();
	} else {
		index = this.menuitems.indexOf( currentItem );
		this.menuitems[index + 1].domNode.focus();
	}
};

PopupMenu.prototype.setFocusByFirstCharacter = function( currentItem: PopupMenuItemType, c: string ) {
	let start;
	let index;
	const char = c.toLowerCase();

	// Get start index for search based on position of currentItem
	start = this.menuitems.indexOf( currentItem ) + 1;
	if ( start === this.menuitems.length ) {
		start = 0;
	}

	// Check remaining slots in the menu
	index = this.getIndexFirstChars( start, char );

	// If not found in remaining slots, check from beginning
	if ( -1 === index ) {
		index = this.getIndexFirstChars( 0, char );
	}

	// If match was found...
	if ( -1 < index ) {
		this.menuitems[index].domNode.focus();
	}
};

PopupMenu.prototype.getIndexFirstChars = function( startIndex: number, char: string ) {
	for ( let i = startIndex; i < this.firstChars.length; i++ ) {
		if ( char === this.firstChars[i] ) {
			return i;
		}
	}

	return -1;
};

/* MENU DISPLAY METHODS */

PopupMenu.prototype.open = function() {
	// set aria-expanded attribute
	this.controller.domNode.setAttribute( 'aria-expanded', 'true' );
	this.domNode.classList.add( 'mr-menu--expanded' );
};

PopupMenu.prototype.close = function( force?: boolean ) {
	requestAnimationFrame( () => {
		if ( force ) {
			this.controller.domNode.removeAttribute( 'aria-expanded' );
			this.domNode.classList.remove( 'mr-menu--expanded' );

			return;
		}

		if ( this.hasFocus ) {
			return;
		}

		if ( this.hasHover ) {
			return;
		}

		if ( this.controller.hasHover ) {
			return;
		}

		this.controller.domNode.removeAttribute( 'aria-expanded' );
		this.domNode.classList.remove( 'mr-menu--expanded' );
	} );
};
